export function useTheme() {
  const colorMode = useColorMode()

  const displayValue = computed(() => {
    if (colorMode.preference === 'system') {
      return 'System'
    }

    return themeVariantToLabel[(colorMode.preference) as ThemeVariant]
  })

  function handleThemeChange(newTheme: ThemeVariant) {
    colorMode.preference = colorMode.preference = newTheme
  }

  return {
    displayValue,
    handleThemeChange,
  }
}
