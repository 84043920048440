<script setup lang="ts">
import { KsIcon } from '@aschehoug/kloss'
import { faUser } from '@fortawesome/pro-duotone-svg-icons'
import { faBars } from '@fortawesome/pro-regular-svg-icons'
import MenuItem from '~/components/header/MenuItem.vue'
import NavLogo from '~/components/header/NavLogo.vue'
import ThemeDropdown from '~/components/header/ThemeDropdown.vue'
import NavLink from '~/components/link/NavLink.vue'
import LogoutButton from './LogoutButton.vue'

const props = withDefaults(defineProps<{
  variant?: LayoutVariant
  menuOptions: Menu[]
  open?: boolean
  handleSelectMenu: (category: Menu) => void
  selectedCategory: MenuOption | null
  toggleMenu: () => void
}>(), {
  open: false,
  variant: 'blurple',
  selectedCategory: null,
  handleSelectMenu: () => {},
})

const { user } = useUserSession()

const { primaryItems, secondaryItems } = useMenuItems(props.menuOptions)
</script>

<template>
  <div class="relative flex items-center gap-x-xl full-width">
    <NavLogo :variant="variant" :open="open" />
    <nav v-if="menuOptions.length > 0" class="flex-1 items-center flex justify-end">
      <ul v-if="primaryItems.length > 0" class="items-center grow gap-l hidden lg:flex">
        <MenuItem
          v-for="item in primaryItems"
          :key="item.label"
          :item="item"
          :variant="variant"
          :selected-category="selectedCategory"
          :handle-select-menu="handleSelectMenu"
        />
      </ul>
      <ul v-if="secondaryItems.length > 0" class="items-center gap-l hidden lg:flex">
        <MenuItem
          v-for="item in secondaryItems"
          :key="item.label"
          :item="item"
          :variant="variant"
          :selected-category="selectedCategory"
          :handle-select-menu="handleSelectMenu"
        />
      </ul>
      <NavLink
        aria-label="Meny"
        :is-selected="open"
        :variant="variant"
        class="text-small justify-center gap-2xs flex lg:hidden"
        :aria-expanded="open"
        aria-haspopup="true"
        @click="toggleMenu"
      >
        Meny
        <KsIcon :icon="faBars" />
      </NavLink>
    </nav>
    <div
      v-if="selectedCategory === 'profile'"
      class="absolute flex flex-col items-center gap-xl p-s rounded-xl bg-white shadow-menu -top-5 -right-4 z-50 animate-fade"
    >
      <NavLink
        :variant="variant"
        :expandable="true"
        :icon="faUser"
        icon-position="left"
        class="!bg-white hover:!bg-white !focus:bg-white !outline-white"
        @click="handleSelectMenu"
      >
        {{ user?.fullName ?? 'Navn Navnesen' }}
      </NavLink>
      <ThemeDropdown />
      <LogoutButton :variant="variant" />
    </div>
  </div>
</template>
