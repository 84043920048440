import type { IconDefinition } from '@fortawesome/fontawesome-common-types'
import type { MenuOption } from '~/constants/menu'

export interface Menu {
  value: MenuOption
  label: string
  expandable: boolean
  href?: string
  external?: boolean
  icon?: IconDefinition
  hide?: boolean
  onClick?: (props?: any) => any
  privacy?: Privacy
}

type Privacy = 'mask' | 'mask-user-input' | 'allow' | 'hidden'

export function useHeaderState() {
  const selectedCategory = ref<MenuOption | null>(null)
  const openSearch = ref<boolean>(false)
  const openMenu = ref<boolean>(false)

  const { y } = useWindowScroll()
  const isScrolled = computed(() => y.value > 200)

  function toggleSearch() {
    openSearch.value = !openSearch.value
  }

  function toggleMenu() {
    openMenu.value = !openMenu.value
  }

  function handleSelectMenu(category: Menu): void {
    if (selectedCategory.value === category.value) {
      selectedCategory.value = null
    }
    else {
      selectedCategory.value = category.value
    }
  }

  return {
    selectedCategory,
    openSearch,
    openMenu,
    isScrolled,
    toggleSearch,
    toggleMenu,
    handleSelectMenu,
  }
}
