<script setup lang="ts">
import { faArrowRightToBracket, faSearch, faUser } from '@fortawesome/pro-duotone-svg-icons'
import DesktopMenu from '~/components/header/DesktopMenu.vue'
import MobileMenu from '~/components/header/MobileMenu.vue'
import ModalSearchInput from '~/components/header/ModalSearchInput.vue'
import { type Menu, useHeaderState } from '~/composables/useHeaderState'

withDefaults(defineProps<{
  variant?: LayoutVariant
}>(), {
  variant: 'blurple',
})

const { selectedCategory, openSearch, openMenu, isScrolled, toggleSearch, toggleMenu, handleSelectMenu } = useHeaderState()

const { user, loggedIn } = useUserSession()

const MENU_CATEGORY_OPTIONS: Menu[] = [
  {
    value: 'lover-og-lovkommentarer',
    label: 'Lover og lovkommmentarer',
    href: '/lover-og-lovkommentarer',
    expandable: false,
  },
  {
    value: 'rettsomrader',
    label: 'Rettsområder',
    href: '/rettsomrader',
    expandable: false,
  },
  {
    value: 'forfattere',
    label: 'Forfattere',
    href: '/forfattere',
    expandable: false,
  },
  {
    value: 'kurs-og-arrangementer',
    label: 'Kurs og arrangementer',
    href: '/kurs-og-arrangementer',
    expandable: false,
    hide: true,
  },
  {
    value: 'sok',
    label: 'Søk',
    icon: faSearch,
    expandable: false,
    onClick: toggleSearch,
  },
  {
    value: 'profile',
    privacy: 'mask',
    label: loggedIn.value ? user?.value?.fullName ?? 'Navn Navnesen' : 'Logg inn',
    icon: loggedIn.value ? faUser : faArrowRightToBracket,
    expandable: !!loggedIn.value,
    href: loggedIn.value ? undefined : '/login/feide',
    external: !loggedIn.value,
    onClick: loggedIn.value ? handleSelectMenu : undefined,
  },
]
</script>

<template>
  <header
    class="bg-white py-6 lg:py-11 sticky top-0 z-50 ease-in-out transition-all duration-500"
    :class="{
      '-translate-y-32': isScrolled && !openMenu,
      'dark:bg-blurple-dark-background': variant === 'blurple' && !openMenu,
      'dark:bg-polar-dark-background': variant === 'polar' && !openMenu,
      'dark:bg-carbon-dark-background': variant === 'carbon' && !openMenu,
      'dark:bg-moss-dark-background': variant === 'moss' && !openMenu,
      'dark:bg-blurple-300': variant === 'blurple' && openMenu,
      'dark:bg-polar-200': variant === 'polar' && openMenu,
      'dark:bg-moss-200': variant === 'moss' && openMenu,
      'dark:bg-carbon-200': variant === 'carbon' && openMenu,

    }"
  >
    <div class="max-w-full px-m">
      <DesktopMenu
        :variant="variant"
        :open="openMenu"
        :toggle-menu="toggleMenu"
        :selected-category="selectedCategory"
        :menu-options="MENU_CATEGORY_OPTIONS"
        :handle-select-menu="handleSelectMenu"
      />
      <MobileMenu
        :variant="variant"
        :menu-options="MENU_CATEGORY_OPTIONS"
        :open="openMenu"
        :toggle-menu="toggleMenu"
        :handle-select-menu="handleSelectMenu"
      />
    </div>
    <ModalSearchInput :open="openSearch" :close="toggleSearch" title="" />
  </header>
</template>

<style scoped lang="postcss">
:deep(.ks-dialog) {
  .ks-dialog-footer {
    @apply p-0;
  }
}
</style>
