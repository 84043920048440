export function useSearch(KEY = 'q', withHistory = true) {
  const { query } = useRoute()

  // FIXME: Remove this mock data
  const searchHistory = ref<Array<string>>(MOCK_SEARCH_HISTORY)

  async function handleSearch(event: Event) {
    const data = new FormData(event.target as HTMLFormElement)
    const queryValue = data.get(KEY) as string

    if (!queryValue) {
      return
    }

    if (withHistory) {
      addSearchToHistory(queryValue)
    }

    navigateTo({
      query: {
        [KEY]: queryValue,
      },
    })

    await nextTick()
  }

  function addSearchToHistory(searchTerm: string) {
    // TODO: API call to save search term
    if (searchTerm && !searchHistory.value.includes(searchTerm)) {
      searchHistory.value.push(searchTerm)
    }
  }

  return {
    query,
    handleSearch,
    searchHistory,
  }
}
