<script setup lang="ts">
import { KsDrawer, KsIcon, KsInput } from '@aschehoug/kloss'
import { faSearch } from '@fortawesome/pro-duotone-svg-icons'
import { faXmark } from '@fortawesome/pro-regular-svg-icons'
import NavLogo from '~/components/header/NavLogo.vue'
import NavLink from '~/components/link/NavLink.vue'
import LogoutButton from './LogoutButton.vue'

const props = withDefaults(defineProps<{
  variant?: LayoutVariant
  menuOptions: Menu[]
  open: boolean
  handleSelectMenu: (category: Menu) => void
  toggleMenu: () => void
}>(), {
  variant: 'blurple',
  handleSelectMenu: () => {},
  toggleMenu: () => {},
})

const { primaryItems } = useMenuItems(props.menuOptions)
</script>

<template>
  <KsDrawer title=" " :open="open" size="100%" position="top" :class="variant" :dismissable="false">
    <template #body>
      <div class="flex-1 flex flex-col gap-xl">
        <div class="flex items-center gap-x-xl full-width">
          <NavLogo :variant="variant" :open="open" />
          <nav v-if="menuOptions.length > 0" class="flex-1 items-center flex justify-end">
            <NavLink
              aria-label="Meny"
              :is-selected="open"
              :variant="variant"
              class="text-small justify-center gap-2xs flex !bg-transparent focus:!bg-transparent !outline-transparent"
              :aria-expanded="open"
              aria-haspopup="true"
              :class="{
                'dark:!text-blurple-500': variant === 'blurple',
                'dark:!text-polar-500': variant === 'polar',
                'dark:!text-moss-500': variant === 'moss',
                'dark:!text-carbon-500': variant === 'carbon',
              }"
              @click="toggleMenu"
            >
              Meny
              <KsIcon :icon="faXmark" />
            </NavLink>
          </nav>
        </div>
        <div class="flex flex-col gap-xl px-m sm:px-l">
          <div :class="variant">
            <div
              class="border rounded-full p-s h-16 relative flex w-full items-center"
              :class="{
                'border-carbon-500': variant === 'carbon',
                'border-blurple-500': variant === 'blurple',
                'border-polar-500': variant === 'polar',
                'border-moss-500': variant === 'moss',
              }"
            >
              <KsInput
                type="text"
                placeholder="Søk i hele Juridika"
                class="!bg-transparent !p-0 !text-small"
                :class="{
                  'dark:text-carbon-200 placeholder:text-blurple-500': variant === 'carbon',
                  'dark:text-blurple-500 placeholder:text-blurple-500': variant === 'blurple',
                  'dark:text-polar-500 placeholder:text-polar-500': variant === 'polar',
                  'dark:text-moss-500 placeholder:text-moss-400': variant === 'moss',
                }"
              />
              <Button aira-label="search" variant="tertiary" size="medium" class="!p-0 absolute right-6 top-5 !bg-transparent" type="submit">
                <KsIcon :icon="faSearch" />
              </Button>
            </div>
          </div>
          <ul v-if="primaryItems.length > 0" :class="variant" class="flex flex-col items-start grow gap-l rounded-b-7xl">
            <li v-for="item in primaryItems" :key="item.label">
              <NuxtLink :aria-label="item.label" :to="item.href" :class="variant" class="text-h4 no-underline">
                {{ item.label }}
              </NuxtLink>
            </li>
          </ul>
        </div>
      </div>
      <LogoutButton :variant="variant" :open="open" />
    </template>
  </KsDrawer>
</template>

<style scoped lang='postcss'>
:deep(.ks-dialog) {
  > .ks-dialog-header {
    @apply hidden;
  }

  > .ks-dialog-body {
    @apply flex flex-col gap-m min-h-screen px-xs sm:px-m;
  }

  &.moss {
    @apply dark:bg-moss-200;

    li > a {
      @apply dark:text-moss-500;
    }
  }

  &.carbon {
    @apply dark:bg-carbon-200;

    li > a {
      @apply dark:text-carbon-500;
    }
  }

  &.polar {
    @apply dark:bg-polar-200;

    li > a {
      @apply dark:text-polar-500;
    }
  }

  &.blurple {
    @apply dark:bg-blurple-300;

    li > a {
      @apply dark:text-blurple-500;
    }
  }
}
</style>
