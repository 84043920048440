<script lang="ts" setup>
import type { NuxtLinkProps } from '#app'
import type { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { KsIcon } from '@aschehoug/kloss'
import { faChevronDown } from '@fortawesome/pro-regular-svg-icons'

const props = defineProps<NuxtLinkProps & {
  variant?: LayoutVariant
  iconLeft?: IconDefinition
  expandable?: boolean
  isSelected?: boolean
}>()

const component = computed(() => (props.href || props.to) ? resolveComponent('NuxtLink') : 'button')
</script>

<template>
  <component
    :is="component"
    :type="component !== 'button' ? undefined : 'button'"
    class="cursor-pointer no-underline group text-small py-2xs px-xs flex items-center rounded-lg outline outline-2 outline-transparent gap-2xs ease-in-out duration-300 transition-all"
    v-bind="$props"
    :class="[variant, isSelected && 'selected']"
    :role="component !== 'button' ? undefined : 'button'"
  >
    <KsIcon
      v-if="iconLeft"
      :icon="iconLeft"
      class="ease-out duration-300 transition-all"
      :class="{
        'text-blurple-500 dark:text-blurple-300 group-hover:text-blurple-500 dark:group-hover:text-blurple-200 dark:group-focus:text-blurple-200': variant === 'blurple',
        'text-carbon-500 dark:text-inverse group-hover:text-carbon-500 dark:group-hover:text-white dark:group-focus:text-white': variant === 'carbon',
        'text-moss-500 dark:text-moss-200 group-hover:text-moss-500 dark:group-hover:text-moss-200 dark:group-focus:text-moss-200': variant === 'moss',
        'text-polar-500 dark:text-tertiary-on_dark group-hover:text-polar-500 dark:group-hover:text-polar-200 dark:group-focus:text-polar-200': variant === 'polar',
      }"
    />
    <slot />
    <KsIcon
      v-if="expandable"
      :icon="faChevronDown"
      class="fill-moss-200 text-blurple-500 ease-out duration-300 transition-all"
      :scale="0.6"
      :class="{
        'rotate-180': isSelected,
        'rotate-360': !isSelected,
        'dark:text-blurple-200 group-hover:text-blurple-500 dark:group-hover:text-blurple-200 dark:group-focus:text-blurple-200': variant === 'blurple',
        'dark:text-white group-hover:text-blurple-500 dark:group-hover:text-white dark:group-focus:text-white': variant === 'carbon',
        'dark:text-moss-200 dark:group-hover:text-moss-200 dark:group-focus:text-moss-200': variant === 'moss',
      }"
    />
  </component>
</template>

<style scoped lang="postcss">
button,
a {
  &[disabled] {
    cursor: not-allowed;
    pointer-events: none;

    @apply !text-carbon-500;

    > svg {
      @apply fill-carbon-100 text-carbon-500 opacity-100;
    }
  }

  &.blurple {
    @apply text-blurple-500 dark:text-blurple-300 hover:bg-blurple-50 dark:hover:bg-blurple-400 focus:bg-blurple-50 dark:focus:bg-blurple-400 focus:outline-blurple-300 dark:focus:outline-blurple-300;

    > svg {
      @apply fill-blurple-500;
    }

    &.selected {
      @apply bg-blurple-50 dark:bg-blurple-400 focus:outline-blurple-300 dark:focus:outline-blurple-200;
    }
  }

  &.moss {
    @apply text-moss-500 dark:text-moss-200 hover:bg-moss-200 dark:hover:bg-moss-400 focus:bg-moss-200 dark:focus:bg-moss-400 focus:outline-moss-200;

    > svg {
      @apply fill-moss-500;
    }

    &.selected {
      @apply bg-moss-200 dark:bg-moss-400 focus:outline-moss-300 dark:focus:outline-moss-200;
    }
  }

  &.polar {
    @apply text-polar-500 dark:text-polar-200 hover:text-polar-400 hover:bg-polar-100 dark:hover:bg-polar-400 focus:bg-polar-100 dark:focus:bg-polar-400 focus:outline-polar-400 dark:focus:outline-polar-200;

    > svg {
      @apply fill-polar-500 dark:text-polar-200;
    }

    &.selected {
      @apply bg-polar-100 dark:bg-polar-400 focus:outline-polar-400 dark:focus:outline-polar-200;
    }
  }

  &.carbon {
    @apply text-carbon-400 dark:text-white hover:text-blurple-500 dark:hover:text-white hover:bg-carbon-100 dark:hover:bg-carbon-400 focus:bg-carbon-100 dark:focus:bg-carbon-400 focus:outline-carbon-200 dark:focus:outline-carbon-200;

    > svg {
      @apply fill-blurple-500;
    }

    &.selected {
      @apply bg-carbon-50 dark:bg-carbon-400 hover:bg-carbon-100 dark:hover:bg-carbon-400 focus:bg-carbon-100 dark:focus:bg-carbon-400 focus:outline-carbon-200;
    }
  }
}
</style>
