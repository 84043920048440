<script setup lang="ts">
import NavLink from '~/components/link/NavLink.vue'

const props = defineProps<{
  item: Menu
  variant: LayoutVariant
  selectedCategory: MenuOption | null
  handleSelectMenu: (category: Menu) => void
}>()

const isSelected = computed(() =>
  props.item.value === props.selectedCategory || props.item.value === useRoute().name,
)

function handleClick() {
  if (props.item.onClick) {
    props.item.onClick(props.item)
  }
  else {
    props.handleSelectMenu(props.item)
  }
}
</script>

<template>
  <li v-if="!item.hide">
    <NavLink
      :variant="variant"
      :aria-label="item.label"
      :to="item.href"
      :icon="item.icon"
      icon-position="left"
      :external="item.external"
      :expandable="item.expandable"
      :is-selected="isSelected"
      :data-dd-privacy="item.privacy"
      @click="handleClick"
    >
      {{ item.label }}
    </NavLink>
  </li>
</template>
