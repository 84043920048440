import type { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { faMoon, faSun } from '@fortawesome/pro-duotone-svg-icons'

export const THEME_VARIANTS = [
  'light',
  'dark',
] as const

export type ThemeVariant = typeof THEME_VARIANTS[number]

export interface ThemeDropdownOption {
  value: ThemeVariant
  label: string
  icon: IconDefinition
}

export const THEME_OPTIONS = [
  {
    value: 'light',
    label: 'Lys',
    icon: faSun,
  },
  {
    value: 'dark',
    label: 'Mørk',
    icon: faMoon,
  },
] as const satisfies Options<string, { icon: IconDefinition }>

export const themeVariantToLabel: { [key in ThemeVariant]?: string } = {
  light: 'Lys',
  dark: 'Mørk',
}
