<script setup lang="ts">
import { KsIcon, KsInput } from '@aschehoug/kloss'
import { faMagnifyingGlass } from '@fortawesome/pro-duotone-svg-icons'
import { faXmark } from '@fortawesome/pro-light-svg-icons'

const props = withDefaults(defineProps<{
  variant?: LayoutVariant
  shape?: SearchInputShape
  size?: InputSize
  name?: string
  resettable?: boolean
  placeholder?: string
  disabled?: boolean
  modelValue?: any
}>(), {
  variant: 'blurple',
  shape: 'rounded',
  size: 'medium',
  resettable: false,
  placeholder: 'Søk',
  disabled: false,
})

const emit = defineEmits<{
  (event: 'update:modelValue', value: string): void
}>()

const localValue = ref(props.modelValue || '')

watch(localValue, (newValue) => {
  emit('update:modelValue', newValue)
})

watch(() => props.modelValue, (newValue) => {
  localValue.value = newValue || ''
})

function reset() {
  if (props.resettable) {
    localValue.value = ''
  }
}
const scale = computed(() => {
  switch (props.size) {
    case 'small':
      return 0.5
    case 'medium':
      return 0.7
    case 'large':
      return 0.8
    default:
      return 1
  }
})
</script>

<template>
  <div class="search-input" :class="[size, `search-${shape}`, `search-input-${variant}`]">
    <KsInput
      v-model="localValue"
      :name="name"
      :type="!resettable ? 'text' : 'search'"
      autocomplete="off"
      :placeholder="placeholder"
      :class="variant"
      :disabled="disabled"
      class="!text-small !bg-transparent !p-0"
    />
    <Button
      aria-label="search"
      variant="tertiary"
      size="medium"
      class="!p-0 absolute !bg-transparent transition-all ease-in-out duration-500"
      :type="resettable && modelValue?.length > 0 ? 'button' : 'submit'"
      @click="reset"
    >
      <KsIcon :icon="resettable && localValue?.length ? faXmark : faMagnifyingGlass" :class="size" :scale="scale" />
    </Button>
  </div>
</template>

<style scoped lang='postcss'>
.search-input {
  @apply border relative flex w-full items-center;

  &.small {
    @apply h-8 px-2xs py-2xs max-w-44;

    :deep(.ks-input) {
      @apply text-xs;
    }
  }

  &.medium {
    @apply h-10 p-xs;

    :deep(.ks-input) {
      @apply text-sm;
    }
  }

  &.large {
    @apply h-12 p-s;

    :deep(.ks-input) {
      @apply text-base;
    }
  }

  &.search-rounded {
    @apply rounded-full;

    &.small > button {
      @apply right-2.5 top-2;
    }

    &.medium > button {
      @apply right-3 top-2.5;
    }

    &.large > button {
      @apply right-3.5;
    }
  }

  &.search-square {
    @apply rounded-lg;

    &.small > button {
      @apply right-2;
    }

    &.medium > button {
      @apply right-2.5 top-2.5;
    }

    &.large > button {
      @apply right-3 top-3;
    }
  }

  &.search-input-carbon {
    @apply border-carbon-500;

    :deep(.ks-input) {
      &.blurple {
        @apply dark:text-carbon-200 placeholder:text-blurple-500;
      }
    }
  }

  &.search-input-blurple {
    @apply border-blurple-500;

    :deep(.ks-input) {
      &.blurple {
        @apply dark:text-carbon-200 placeholder:text-blurple-500;
      }
    }
  }

  &.search-input-polar {
    @apply border-polar-500;

    :deep(.ks-input) {
      &.polar {
        @apply dark:text-polar-500 placeholder:text-polar-500;
      }
    }
  }

  &.search-input-moss {
    @apply border-moss-500;

    :deep(.ks-input) {
      &.moss {
        @apply dark:text-moss-500 placeholder:text-moss-400;
      }
    }
  }
}
</style>
