<script setup lang='ts'>
import { faPalette } from '@fortawesome/pro-duotone-svg-icons'
import Dropdown from '../form/Dropdown.vue'

const { displayValue, handleThemeChange } = useTheme()

const theme = ref()
</script>

<template>
  <div class="flex flex-col gap-m">
    <h5>Preferanser</h5>
    <label for="theme" aria-label="Tema" class="flex flex-col gap-2xs">
      <span>Tema</span>
      <Dropdown
        id="theme"
        v-model="theme"
        :options="[...THEME_OPTIONS]"
        variant="outlined"
        :icon="faPalette"
        icon-position="left"
        :multiple="false"
        :placeholder="`Velg tema: ${displayValue}`"
        :option-icon="(theme: ThemeDropdownOption) => theme.icon"
        :option-label="(theme: ThemeDropdownOption) => theme.label"
        :option-id="(theme: ThemeDropdownOption) => theme.value"
        :deselectable="() => false"
        @select="(theme: ThemeDropdownOption) => handleThemeChange(theme.value)"
      />
    </label>
  </div>
</template>
