<script setup lang="ts">
import type { IconDefinition } from '@fortawesome/fontawesome-common-types'
import { KsButton, KsDialog, KsIcon } from '@aschehoug/kloss'

const props = withDefaults(defineProps<{
  title: string
  body?: string
  icon?: IconDefinition
  defaultOpen?: boolean
  dissmissable?: boolean
}>(), {
  dissmissable: true,
})

const emit = defineEmits(['confirm', 'cancel', 'close'])

const open = ref(props.defaultOpen ?? false)

defineExpose({
  isOpen: open,
  show() {
    open.value = true
  },
  hide() {
    open.value = false
  },
})

function handleClose() {
  open.value = false
  emit('close')
}

function handleCancel() {
  open.value = false
  emit('cancel')
}

function handleConfirm() {
  emit('confirm')
}
</script>

<template>
  <KsDialog class="px-2xl py-xl !rounded-lg" :dismissable="false" :open="open" :title="title" @close="handleClose">
    <template #header>
      <div
        v-if="icon || $slots.icon" class="w-2xl aspect-square flex justify-center items-center rounded-lg bg-blurple-100"
      >
        <KsIcon v-if="icon" :icon="icon" :scale="1.65" />
        <slot v-else-if="$slots.icon" name="icon" />
      </div>
    </template>
    <template #body>
      <p v-if="body" v-text="body" />
      <slot v-else name="body" />
    </template>
    <template #footer>
      <div v-if="!$slots.footer" class="flex gap-2xs">
        <KsButton
          variant="tertiary" shape="rounded" @click="handleCancel"
        >
          Avbryt
        </KsButton>
        <KsButton
          variant="primary" shape="rounded" @click="handleConfirm"
        >
          Bekreft
        </KsButton>
      </div>
      <slot name="footer" />
    </template>
  </KsDialog>
</template>

<style scoped>
:deep(.ks-dialog) {
  .ks-dialog-header > h1 {
    @apply text-4xl;
  }

  /* FIXME: Would be best to use flex+gap directly on the KsDialog instead of padding on header + footer, but for some reason the flex gap disappears during the closing animation of the Modal */

  .ks-dialog-header {
    @apply p-0 pb-m w-fit;
  }

  .ks-dialog-body {
    @apply p-0;
  }

  .ks-dialog-footer {
    @apply p-0 pt-m;
  }
}

.ks-dialog-backdrop {
  @apply bg-blurple-500/70;
}
</style>
